<template>
  <div>
      <CRow class="justify-content-between mb-3">
        <CCol sm="auto"
          ><div class="ui-type-display-lg">Novità</div>
        </CCol>
      </CRow>

      <div class="loading text-center" v-if="loading">
        <CSpinner color="primary" size="lg" />
      </div>

      <MarketBox v-for="market in markets" :key="market.id" :market="market" />
    </div>
  </div>
</template>

<script>
import { GetDataManagerNew, ApiCall } from "../../ds";
import { Query } from "@syncfusion/ej2-data";
import { MarketBox } from "./partials";
import { mapGetters } from "vuex";

export default {
  name: "UiMarkets",
  components: {
    MarketBox,
  },

  data() {
    return {
      initialized: false,
      loading: false,
      markets: [],
      numPages: 1,
      currentPage: 1,
    };
  },

  computed: {
    ...mapGetters("connections", ["familyId"]),
  },

  mounted() {
    this.initialized = true;
    this.getMarkets();
  },

  watch: {
    currentPage: function () {
      this.getMarkets();
    },
  },

  methods: {
    getMarkets() {
      const self = this;
      self.loading = true;
      const query = new Query();
      const itemsPerPage = 1;
      query.page(this.currentPage, itemsPerPage);
      ApiCall(
        GetDataManagerNew("family_markets", [this.familyId]),
        query,
        (e) => {
          self.numPages = Math.ceil(e.actual.payload.Count / itemsPerPage);
          self.markets = e.result;
          self.loading = false;
        },
        (e) => {
          console.error(e);
          self.loading = false;
        }
      );
    },

    changeZipCode() {},
  },
};
</script>
