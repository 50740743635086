<template>
  <div>
    <DashboardFamilyMarket/>
    <DashboardFamily/>
  </div>
</template>

<script>
import DashboardFamily from "./DashboardFamily.vue";
import DashboardFamilyMarket from "./DashboardFamilyMarket.vue";
// import DashboardIsland from "./DashboardIsland.vue";

export default {
  data() {
    return {
    };
  },
  computed: {
    showMarket () {
      return this.$store.state.role.market__id > 0;
    },
    showProducer () {
      return this.$store.state.role.producer__id > 0;
    },
    showIsland () {
      return this.$store.state.role.island__id > 0;
    },
  },
  components: {
    DashboardFamily,
    DashboardFamilyMarket,
    // DashboardIsland,
  },
};
</script>
